<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader':
				!application ||
				!_getFishes ||
				!_getGuns ||
				!_getRegionList ||
				!_getPondList ||
				!$store.state.userObject,
		}"
	>
		<v-preloader
			v-if="
				!application ||
				!_getFishes ||
				!_getGuns ||
				!_getRegionList ||
				!_getPondList ||
				!$store.state.userObject
			"
			:message="errorMessage"
		></v-preloader>

		<div
			class="container"
			v-if="
				application &&
				_getFishes &&
				_getGuns &&
				_getRegionList &&
				_getPondList &&
				$store.state.userObject
			"
		>
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['TradeManagementSupervisor']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">
											<!--                      Журнал учета лова рыбных ресурсов и других водных животных (промысловый журнал)-->
											{{ $t('sidebar.trade_list') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST">
									<!--@submit.prevent="onSubmit"-->

									<!--<div class="form__line">
                    <div class="form__line--left">
                      <div class="form__line--title">
                        Наименование
                      </div>
                    </div>
                    <div class="form__line--right">
                      <div class="row">
                        <div class="col-xl-5">
                          <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.name ? 'input--error' : '']">
                            <input type="text" class="input-linck" required="" name="name" :value="application.name" disabled :placeholder="$t('headers.name')">
                          </div>
                          <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.name"><p v-for="(massage, massageInd) in errorMessage.messages.name" :key="massageInd">{{ massage }}</p></div>
                        </div>
                      </div>
                    </div>
                  </div>-->

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Разрешение</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															:value="formList.permission"
															disabled
															placeholder="Значение"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<hr class="form__line" />

									<div v-if="permission">
										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">{{ $t('headers.catch_date') }}</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-5">
														<div
															class="form__line--input"
															:class="[
																errorMessage.messages && errorMessage.messages.catch_at
																	? 'input--error'
																	: '',
															]"
														>
															<input
																type="text"
																class="input-linck"
																required=""
																name="catch_at"
																:value="application.catch_at | formatDate"
																disabled
																placeholder="Дата улова"
															/>
														</div>
														<div
															class="input-required"
															v-if="errorMessage.messages && errorMessage.messages.catch_at"
														>
															<p
																v-for="(massage, massageInd) in errorMessage.messages.catch_at"
																:key="massageInd"
															>
																{{ massage }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">{{ $t('inputs.region') }}</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-5">
														<div class="form__line--input">
															<input
																type="text"
																class="input-linck"
																:value="application.permission.region.value"
																disabled
																placeholder="Значение"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">{{ $t('inputs.pond_name') }}</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-5">
														<div class="form__line--input">
															<input
																type="text"
																class="input-linck"
																:value="application.permission.pond.value"
																disabled
																placeholder="Значение"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>

										<hr class="form__line" />

										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">{{ $t('headers.fishers_count') }}</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-8">
														<div
															class="form__line--input"
															:class="[
																errorMessage.messages && errorMessage.messages.fishermen
																	? 'input--error'
																	: '',
															]"
														>
															<input
																type="text"
																step="1"
																min="0"
																class="input-linck"
																required=""
																:value="application.fishermen"
																disabled
																name="fishermen"
																placeholder="0"
															/>
														</div>
														<div
															class="input-required"
															v-if="errorMessage.messages && errorMessage.messages.fishermen"
														>
															<p
																v-for="(massage, massageInd) in errorMessage.messages.fishermen"
																:key="massageInd"
															>
																{{ massage }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">{{ $t('headers.fishing_things') }}</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-8">
														<div
															class="quota-fish__line"
															v-for="(gear, gearIndex) in formList.gears"
															:key="gearIndex"
														>
															<div class="form__line--input">
																<div class="row align-items-center">
																	<div class="col-xl-10">
																		<div class="quota-fish__item">
																			<div class="row align-items-center">
																				<div class="col-xl-7">
																					<div class="form__line--input">
																						<input
																							type="text"
																							class="input-linck"
																							v-model="gear.name"
																							disabled
																							placeholder="0"
																						/>
																					</div>
																				</div>
																				<div class="col-xl-5">
																					<div class="form__line--input">
																						<input
																							type="number"
																							step=".01"
																							min="0"
																							class="input-linck"
																							disabled
																							v-model="gear.value"
																							placeholder="0"
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">
													Видовой состав улова, виды рыб / тон.
													<!--килограмм-->
												</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-8">
														<div
															class="quota-fish__line"
															v-for="(fish, fishIndex) in formList.fish"
															:key="fishIndex"
														>
															<div class="form__line--input">
																<div class="row align-items-center">
																	<div class="col-xl-10">
																		<div class="quota-fish__item">
																			<div class="row align-items-center">
																				<div class="col-xl-7">
																					<div class="form__line--input">
																						<input
																							type="text"
																							class="input-linck"
																							v-model="fish.name"
																							disabled
																							placeholder="0"
																						/>
																					</div>
																				</div>
																				<div class="col-xl-5">
																					<div class="form__line--input">
																						<input
																							type="number"
																							step=".01"
																							min="0"
																							class="input-linck"
																							disabled
																							v-model="fish.value"
																							placeholder="0"
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">{{ $t('headers.catch_volume') }}</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-8">
														<div
															class="form__line--input"
															:class="[
																errorMessage.messages && errorMessage.messages.catch_size
																	? 'input--error'
																	: '',
															]"
														>
															<input
																type="text"
																class="input-linck"
																required=""
																:value="application.catch_size"
																disabled
																name="catch_size"
																placeholder="0"
															/>
														</div>
														<div
															class="input-required"
															v-if="errorMessage.messages && errorMessage.messages.catch_size"
														>
															<p
																v-for="(massage, massageInd) in errorMessage.messages.catch_size"
																:key="massageInd"
															>
																{{ massage }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<!--
                    <hr class="form__line">

                    <div class="form__line">
                      <div class="form__line--left">
                        <div class="form__line--title">
                          Всего отпущено рыбы, тон.
                        </div>
                      </div>
                      <div class="form__line--right">
                        <div class="row">
                          <div class="col-xl-5">
                            <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.released_size ? 'input--error' : '']">
                              <input type="text" class="input-linck" required="" :value="application.released_size" disabled name="released_size" placeholder="0">
                            </div>
                            <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.released_size"><p v-for="(massage, massageInd) in errorMessage.messages.released_size" :key="massageInd">{{ massage }}</p></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form__line">
                      <div class="form__line--left">
                        <div class="form__line--title">
                          Дата отгрузки
                        </div>
                      </div>
                      <div class="form__line--right">
                        <div class="row">
                          <div class="col-xl-5">
                            <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.shipment_at ? 'input--error' : '']">
                              <input type="text" class="input-linck" required="" name="shipment_at" :value="application.shipment_at | formatDate" disabled placeholder="Дата отгрузки">
                            </div>
                            <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.shipment_at"><p v-for="(massage, massageInd) in errorMessage.messages.shipment_at" :key="massageInd">{{ massage }}</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form__line">
                      <div class="form__line--left">
                        <div class="form__line--title">
                          № накладной
                        </div>
                      </div>
                      <div class="form__line--right">
                        <div class="row">
                          <div class="col-xl-5">
                            <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.consignment_number ? 'input--error' : '']">
                              <input type="text" class="input-linck" required="" name="consignment_number" :value="application.consignment_number" disabled placeholder="№ накладной">
                            </div>
                            <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.consignment_number"><p v-for="(massage, massageInd) in errorMessage.messages.consignment_number" :key="massageInd">{{ massage }}</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form__line">
                      <div class="form__line--left">
                        <div class="form__line--title">
                          № автомашины, судна
                        </div>
                      </div>
                      <div class="form__line--right">
                        <div class="row">
                          <div class="col-xl-5">
                            <div class="form__line--input" :class="[errorMessage.messages && errorMessage.messages.transport_number ? 'input--error' : '']">
                              <input type="text" class="input-linck" required="" name="transport_number" :value="application.transport_number" disabled placeholder="№ автомашины, судна">
                            </div>
                            <div class="input-required" v-if="errorMessage.messages && errorMessage.messages.transport_number"><p v-for="(massage, massageInd) in errorMessage.messages.transport_number" :key="massageInd">{{ massage }}</p></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    -->
									</div>

									<!--<hr class="form__line">-->
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api } from '@/boot/axios'

export default {
	data() {
		return {
			permission: null,
			permissions: null,

			application: null,

			regionValue: null,
			reservoirsValue: null,
			gearValue: null,

			fildsList: [],
			tradeFish: null,
			tradeFishValue: null,

			gearsList: [],
			tradeGears: null,
			tradeGearsValue: null,

			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			dataEcp: null,
			formData: null,
		}
	},
	computed: {
		...mapGetters(['_getRegionList', '_getPondList', '_getFishes', '_getGuns']),
		formList() {
			let permissionList = []
			permissionList = this.permissions.find((element) => {
				if (this.application.permission_id == element.id) return true
			})

			let gearsList = []
			gearsList = this._getGuns.filter((element) => {
				return this.application.gears.find((e) => {
					if (e.gear_id == element.id) {
						element.value = e.value
						return true
					}
				})
			})

			let fishList = []
			fishList = this._getFishes.filter((element) => {
				return this.application.fishsitem.find((e) => {
					if (e.fish_id == element.id) {
						element.value = String(e.value).substr(1)
						return true
					}
				})
			})

			this.permission = permissionList
			let list = {
				permission: permissionList.code,
				fish: fishList,
				gears: gearsList,
			}
			return list
		},
	},
	methods: {
		...mapActions(['_fetchRegionList', '_fetchPondList', '_fetchFishes', '_fetchGuns']),
		apiGetTrade() {
			api
				.get('/user/' + this.$route.params.userId + '/trade/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.application = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},

		apiGetPermissionList() {
			api
				.get('user/' + this.$route.params.userId + '/permissions')
				.then((response) => {
					if (response.data) {
						this.permissions = response.data.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	beforeMount() {
		this.apiGetPermissionList()
		this.apiGetTrade()
		this._fetchFishes()
		this._fetchGuns()
		this._fetchPondList()
		this._fetchRegionList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
